<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Processes</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="process.name"
                        hide-details
                        prepend-icon="mdi-hammer-wrench"
                        label="Name"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-textarea
                        v-model="process.description"
                        prepend-icon="mdi-text"
                        label="Description"
                        hide-details
                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveProcess"
                rounded
                :loading="loading"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'ProcessForm',
    props: {
        process: {
            type: Object,
            default: () => ({}),
        },
        originalProcess: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        processSettings: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        processDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalProcess, this.process)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveProcess() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                const process = await API.createProcess({
                    settingId: this.processSettings.id,
                    ...this.process,
                })
                this.$emit('addProcess', process)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const process = await API.updateProcess({
                    settingId: this.processSettings.id,
                    processId: this.process.id,
                    ...this.processDiff,
                })
                this.$emit('replaceProcess', process)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
